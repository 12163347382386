@import "../../styles/custom.scss";

.app-header {
    background-color: $primary;
    // font-size: calc(10px + 2vmin);
    color: $white;
    left: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: auto;
    width: $sidebar-width;

    @media(max-width: 1000px) {
        position: relative;
        width: 100%;
        height: auto;

        .nav-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    .header-brand{
        font-weight: bold;
        color: $white;
    }

    .image-container, .icons-stack{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-img {
        max-width: 160px;
    }

    .icon-link {
        width: 35px;
    }

    .circle-icon-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 5px;
    }
      
    .icon {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    .nav-link{
        text-align: left;
        font-weight: bold;
        font-size: 16px;
    }

    .link-icon{
        font-size: 1.25em;
    }

    .inverse-icon {
        // filter: invert(1);
        color: $primary;
        font-size: 1.5em;
    }

    .norm-icon {
        font-size: 1.5em;
    }

    .active-nav-link {
        color: $white;
    }
}

@include color-mode(dark){
    .app-header {
        background-color: #1d2a3a;
    }
}
