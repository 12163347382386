@import "../../styles/custom.scss";

.project-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 300px;
    overflow: hidden;
}

.project-intro-img, .project-section-img {
    max-width: 100%;
    height: auto;
}

.project-overview , .project-footer{
    background: #fafafa;
    border: 1px solid rgba(0,0,0,0.025);
}

@include color-mode(dark) {
    .project-overview, .project-footer {
        background-color: $secondary-bg;
        // border: 1px solid #fafafa;
    }
}