@import "../../styles/custom.scss";

.project-card-img {
    max-width: 100%;
    height: auto;
}

.project-card {
    background-color: #fafafa;
    border: 1px solid rgba(0,0,0,0.025);
}

@include color-mode(dark) {
    .project-card {
        background-color: $secondary-bg;
        // border: 1px solid #fafafa;
    }
}

.project-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 177px;
    overflow: hidden;
}

.hoverable-card {
    position: relative;
    overflow: hidden;
}

.hoverable-card .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.6s;
}

.hoverable-card:hover .card-overlay {
    opacity: 1;
}