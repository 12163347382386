@import "../../styles/custom.scss";

.timeline-item-title.active {
    background-color: transparent !important;
}

.timeline-vertical-circle {
    .active {
        background-color: $primary !important;
    }
}

@include color-mode(dark){
    .timeline-card-content {
        background-color: $secondary-bg;
        
        span{
            background-color: $secondary-bg;
        }

        .rc-card-title, .card-sub-title, .card-description > p {
            color:rgb(222, 226, 230);
        }
    }
}
