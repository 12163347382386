@import "../../styles/custom.scss";

.section-title:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 100%;
    background: $primary;
    position: absolute;
    left: 0;
    top: 0;
}

.section-title {
    position: relative;
    padding-left: 1.5rem;
}