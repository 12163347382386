@import "~bootstrap/scss/bootstrap";
$color-mode-type: data;
$primary: cornflowerblue;
$sidebar-width: 280px;

$secondary-bg: #151e29;

.stack-justify {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 100%;
}

hr {
    opacity: 0.1;
}

.text-justify{
    text-align: justify;
}

.dark-hr {
    display: none;
}

@include color-mode(dark) {
    .dark-hr {
        display: block;
    }
}